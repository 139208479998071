import { EventService } from 'src/app/event.service';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { NumerationComponent } from './numeration/numeration.component';
import { AppServices } from '../app-services.service';

@Injectable()
export class NumerationDeactivateGuard
  implements CanDeactivate<NumerationComponent>
{
  constructor(private event: EventService, private appServices: AppServices) {}

  canDeactivate(
    target: NumerationComponent,
    _currRoute: any,
    _currState,
    nextState
  ) {
    if (target.needToReloadDefinitions) {
      if (!target.reloadingDefinitions) {
        target.reloadingDefinitions = true;
        // this.event.getnume
        this.getDocumentsNumerations({ url: nextState.url, component: target });
      }
      return false;
    }
    return true;
  }

  getDocumentsNumerations(reload) {
    return new Promise((resolve) => {
      this.appServices.getAuth('document/sales/numeration').subscribe(
        (res) => {
          const staticData = this.event.decryptString(
            localStorage.getItem('staticData')
          );
          staticData.documentsNumerations = res;
          localStorage.setItem(
            'staticData',
            this.event.encryptString(JSON.stringify(staticData))
          );
          this.event.navigateToNextUrl(reload);
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }
}
